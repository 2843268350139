import {
	ContentTree,
	Node,
	Social,
	ScheduledBlogPosts,
	NodeBlogContent,
	reportProblem,
} from "../../../services/contentNodes";
import axios from "axios";
import router from "@/router";

export default {
	/**
	 * Called by the fetchClientData action on a positive API response.
	 * Commits the client data to the store.
	 * Creates a blank class instance of a ContentTree and then applies the data received from the api response
	 * to the class instance, allowing us to use the class methods on the tree e.g. addChildNode();
	 *
	 * @param {Object} state vuex state
	 * @param {Object} data client data received from the API
	 */
	setClientData(state, clientData) {
		state.clientData = clientData;
	},
	setClientContentTree(state, contentTreeData) {
		let blankTreeClassInstance = new ContentTree();

		if (contentTreeData) {
			blankTreeClassInstance.links = contentTreeData.links;
			blankTreeClassInstance.nodeIds = contentTreeData.nodeIds;
			blankTreeClassInstance.rootNodeId = contentTreeData.rootNodeId;
			blankTreeClassInstance.nodes = contentTreeData.nodes;
		}

		state.clientContentTree = blankTreeClassInstance;
	},
	setClientContentTreeReach(state, reach) {
		state.rootNodeReach = reach;
	},
	/**
	 * Calls the addChildNode class method to add a new child to the content tree under the given parent
	 * @param {Object} state vuex state
	 * @param {String} parentNodeId the id of the parent node that the child is being created under
	 */
	addChildNode(state, { parentNodeId, author }) {
		const childNode = state.clientContentTree.addChildNode(
			parentNodeId,
			author
		);
		if (childNode.level === 1) {
			childNode.fieldGroups.status.fields.status.value = "Category";
		}
		state.currentNode = childNode;
	},
	async removeNode(state, nodeToBeRemovedId) {
		console.log([state, nodeToBeRemovedId]);
		state.currentNode = new Node();
		await state.clientContentTree.removeNode(nodeToBeRemovedId);
		return;
	},
	async clearNode(state) {
		state.currentNode = new Node();
		return;
	},
	clearSelectedNode(state) {
		const route = router.currentRoute.value;
		const query = { ...route.query };
		delete query.node;
		router.replace({ query });
		state.currentNode = new Node();
		state.activeNode = "";
		return;
	},
	clearNodeInState(state) {
		state.currentNode = new Node();
		state.activeNode = "";
		state.highlightedNode = new Node();
		state.closeNodeOverview = !state.closeNodeOverview;
		return;
	},
	/**
	 * Called by the selectNode action when a node was selected.
	 * Searches the client content Tree for the Node & sets the currentNode as the address of the found node.
	 *
	 * @param {*} state vuex state
	 * @param {String} nodeId ID of the node that was selected
	 */
	setNode(state, nodeData) {
		// // Filters all the nodes to select the node with a matching ID
		// let currentNodeArray = state.clientContentTree.nodes.find((node) => node.id === nodeId);
		// // Gets the object out of the array
		// let currentNode = currentNodeArray;
		// // Sets the Node Object in the state
		state.currentNode = nodeData;

		// Reset hasCurrentNodeBeenEdited
		state.hasCurrentNodeBeenEdited = false;

		return;
	},
	setNodeBlogContentID(state, nodeId) {
		state.currentNodeBlogContent.nodeID = nodeId;
		return;
	},
	setComment: (state, payload) => {
		console.log("setComment", payload);
		return (state.comment = payload);
	},
	setOpenCommentPanel: (state, payload) => {
		console.log("setOpenCommentPanel", payload);
		return (state.openCommentPanel = payload);
	},
	setCancelComment: (state, payload) => {
		return (state.cancelComment = payload);
	},
	setResolveComment(state, id) {
		state.clientNodeComments.forEach((comment) => {
			if (comment.id === id || comment.parentCommentID === id) {
				comment.resolved = true;
			}
		});
	},
	setDeleteComment(state, id) {
		state.clientNodeComments = state.clientNodeComments.filter(
			(comment) => comment.id !== id
		);
	},
	setOpenKeywordPlanner: (state, payload) => {
		return (state.openKeywordPlanner = payload);
	},
	setNodeBlogContent(state, payload) {
		state.currentNodeBlogContent.id = payload.id;
		state.currentNodeBlogContent.nodeID = payload.nodeID;
		state.currentNodeBlogContent.featuredImageURL = payload.featuredImageURL;
		state.currentNodeBlogContent.blogHTML = payload.blogHTML;
		state.currentNodeBlogContent.reviewed = payload.reviewed;
		state.currentNodeBlogContent.title = payload.title;
		state.currentNodeBlogContent.hasbeenPublished = payload.hasbeenPublished;
		state.currentNodeBlogContent.date = payload.date;
		state.hasCurrentNodeBlogContentBeenEdited = true;

		return;
	},
	setNodeBlogContentHTML(state, payload) {
		state.currentNodeBlogContent.blogHTML = payload;
		state.hasCurrentNodeBlogContentBeenEdited = true;
	},
	setNodeBlogContentFeaturedImage(state, payload) {
		state.currentNodeBlogContent.featuredImageURL = payload;
		state.hasCurrentNodeBlogContentBeenEdited = true;
		return;
	},

	/**
	 * Called by the selectNode action when a node was selected.
	 * Searches the client content Tree for the Node & sets the currentNode as the address of the found node.
	 *
	 * @param {*} state vuex state
	 * @param {String} nodeId ID of the node that was selected
	 */
	highlightNode(state, nodeId) {
		// Filters all the nodes to select the node with a matching ID
		let currentNodeArray = state.clientContentTree.nodes.filter(
			(node) => node.id === nodeId
		);
		// Gets the object out of the array
		let currentNode = currentNodeArray[0];
		// Sets the Node Object in the state
		state.highlightedNode = currentNode;
		return;
	},
	/**
	 * Called by the selectNode action when a node was selected.
	 * Searches the client content Tree for the Node & sets the currentNode as the address of the found node.
	 *
	 * @param {*} state vuex state
	 * @param {String} nodeId ID of the node that was selected
	 */
	clearHighlightNode(state) {
		// Sets the Node Object in the state
		state.highlightedNode = new Node();
		return;
	},
	/**
	 * Reactivley updates the currentNode's field value
	 * @param {state} state vuex state
	 * @param {Object} payLoad contains three strings, the fieldGroup, the specific field & the new value {fieldGroup, field, value}
	 */
	updateNodeFieldValue(state, payLoad) {
		state.currentNode.fieldGroups[payLoad.fieldGroup].fields[
			payLoad.field
		].value = payLoad.value;
		state.hasCurrentNodeBeenEdited = true;
	},
	updateNodeBlogValue(state, payLoad) {
		state.currentNodeBlogContent[payLoad.field] = payLoad.value;
	},
	setClientNodeMediaTempStorage(state, payLoad) {
		state.clientNodeMediaTempStorage = payLoad;
	},
	setHasCurrentNodeBlogContentBeenEdited(state, payLoad) {
		state.hasCurrentNodeBlogContentBeenEdited = payLoad;
	},
	setClientName(state, clientName) {
		state.clientData.clientName = clientName;
	},
	setClientSlug(state, clientSlug) {
		state.clientData.clientSlug = clientSlug;
	},
	setClientGoogle(state, updatedKeyValue) {
		// Get the new key/value and current data
		let attr = Object.keys(updatedKeyValue)[0];
		let newValue = Object.values(updatedKeyValue)[0];
		let currentGoogleData = state.clientData.googleData;
		if (!currentGoogleData) {
			currentGoogleData = { metrics: {} };
		}
		// Add/update the new data
		currentGoogleData[attr] = newValue;
		// Save the updated to the DB
		const params = new URLSearchParams();
		params.append("clientSlug", state.clientData.slug);
		params.append("data", JSON.stringify(currentGoogleData));
		// console.log(params);
		axios
			.post("/api/clients/googleData/updateGoogleData", params, {
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
			.then(async () => {
				console.log("Google Data set");
				// Assuming that works, update the state.
				state.clientData.googleData = currentGoogleData;
			})
			.catch((err) => console.error(err.response));
	},
	setClientGoogleState(state, updatedKeyValue) {
		// Get the new key/value and current data

		let attr = Object.keys(updatedKeyValue)[0];

		let newValue = Object.values(updatedKeyValue)[0];

		let currentGoogleData = state.clientData.googleData;

		if (!currentGoogleData) {
			currentGoogleData = { metrics: {} };
		}

		// Add/update the new data
		currentGoogleData[attr] = newValue;

		state.clientData.googleData = currentGoogleData;
	},
	setClientGoogleMetric(state, updatedKeyValue) {
		// Get the new key/value and current data

		let metric = updatedKeyValue.metricname;
		let value = updatedKeyValue.data;

		let currentGoogleData = state.clientData.googleData;
		if (!currentGoogleData) {
			currentGoogleData = { metrics: {} };
		}
		// Add/update the new data
		currentGoogleData.metrics[metric] = value;
		// Save the updated to the DB
		const params = new URLSearchParams();
		params.append("clientSlug", state.clientData.slug);
		params.append("data", JSON.stringify(currentGoogleData));
		//console.log(params);
		axios
			.post("/api/clients/googleData/updateGoogleData", params, {
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
			.then(async () => {
				// Assuming that works, update the state.
				state.clientData.googleData = currentGoogleData;
			})
			.catch((err) => console.error(err.response));
	},
	setClientGoogleMetricState(state, updatedKeyValue) {
		let metric = updatedKeyValue.metricname;
		let value = updatedKeyValue.data;

		let currentGoogleData = state.clientData.googleData;

		if (!currentGoogleData) {
			currentGoogleData = { metrics: {} };
		}

		// Add/update the new data
		currentGoogleData.metrics[metric] = value;

		state.clientData.googleData = currentGoogleData;
	},
	setClientFacebook(state, updatedKeyValue) {
		// Get the new key/value and current data
		let attr = Object.keys(updatedKeyValue)[0];
		let newValue = Object.values(updatedKeyValue)[0];
		let currentFacebookData = state.clientData.facebookData;
		// Add/update the new data
		currentFacebookData[attr] = newValue;
		// Save the updated to the DB
		const params = new URLSearchParams();
		params.append("clientSlug", state.clientData.slug);
		params.append("data", JSON.stringify(currentFacebookData));
		// console.log(params);
		axios
			.post("/api/clients/facebookData/updateFacebookData", params, {
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
			.then(async () => {
				console.log("Facebook Data set");
				// Assuming that works, update the state.
				state.clientData.facebookData = currentFacebookData;
			})
			.catch((err) => console.error(err.response));
	},
	setClientFacebookState(state, updatedKeyValue) {
		// Get the new key/value and current data
		let attr = Object.keys(updatedKeyValue)[0];

		let newValue = Object.values(updatedKeyValue)[0];

		let currentFacebookData = state.clientData.facebookData;

		// Add/update the new data

		currentFacebookData[attr] = newValue;

		// Save the updated to the DB

		state.clientData.facebookData = currentFacebookData;
	},
	setClientFacebookMetric(state, updatedKeyValue) {
		// Get the new key/value and current data
		// console.log(updatedKeyValue);
		let metric = updatedKeyValue.metricname;
		let value = updatedKeyValue.data;
		// console.log('metric: ' + metric);
		// console.log('value: ' + value);
		let currentFacebookData = state.clientData.facebookData;
		// Add/update the new data
		currentFacebookData.metrics[metric] = value;
		// Save the updated to the DB
		const params = new URLSearchParams();
		params.append("clientSlug", state.clientData.slug);
		params.append("data", JSON.stringify(currentFacebookData));
		//console.log(params);
		axios
			.post("/api/clients/facebookData/updateFacebookData", params, {
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
			.then(async () => {
				console.log("Facebook Data set");
				// Assuming that works, update the state.
				state.clientData.facebookData = currentFacebookData;
			})
			.catch((err) => console.error(err.response));
	},
	setFilters(state, filters) {
		["status", "author", "created"].forEach((key) => {
			if (filters[key] === "Show All") {
				delete filters[key];
			}
		});
		state.contentTreeFilters = filters;
	},
	setPageLoadedObj(state, value) {
		state.clientPagesLoaded = value;
	},
	setPageLoaded(state, value) {
		if (typeof value.val !== "undefined") {
			if (value.vue === "analyticsComponents") {
				state.clientPagesLoaded.analyticsComponents[value.component] =
					value.val;
			}
			if (value.vue === "paidSocialComponents") {
				state.clientPagesLoaded.paidSocialComponents[value.component] =
					value.val;
			}
			if (value.vue === "ppcComponents") {
				state.clientPagesLoaded.ppcComponents[value.component] = value.val;
			}
			if (value.vue === "seoComponents") {
				state.clientPagesLoaded.seoComponents[value.component] = value.val;
			}
			if (value.vue == "organicSocialComponents") {
				state.clientPagesLoaded.organicSocialComponents[value.component] =
					value.val;
			}
		}
	},
	removeComment(state, id) {
		state.clientNodeComments = state.clientNodeComments.filter(
			(comment) => comment.id !== id
		);
	},
	addToClientNodeComments(state, value) {
		state.clientNodeComments.push(value);
	},
	setMentions(state, value) {
		state.mentions = value;
	},
	// setMentionsUpdateComment(state, value){
	// 	state.mentions = value;
	// 	const result = state.mentions.find((obj) => obj.id === value.id);
	// 	if (result) {
	// 		result.comment = value.comment;
	// 	}
	// },
	setMentionsPushToArray(state, value) {
		state.mentions.push(value);
	},
	setClientNodeComments(state, value) {
		state.clientNodeComments = value;
	},
	setEditComment(state, value) {
		state.clientNodeComments.forEach((comment) => {
			if (comment.id === value.commentID) {
				comment.comment = value.comment;
			}
		});
	},
	setHasCommentsBeenUpdated(state, value) {
		state.hasCommentsBeenUpdated = value;
	},
	setHidePageLoader(state, value) {
		state.loaders.hidePageLoader = value;
	},
	setHeaderGraphBlockReset(state, value) {
		state.HeaderGraphBlockReset = value;
	},
	setHeaderGraphBlockReset2(state, value) {
		console.log("headerGraphBlockReset2 MUTATION === true so reload");
		state.HeaderGraphBlockReset2 = value;
	},
	setHeaderGraphBlockReset3(state, value) {
		console.log("headerGraphBlockReset3 MUTATION === true so reload");
		state.HeaderGraphBlockReset3 = value;
	},
	SET_TOKENS(state, data) {
		state.tokens = data;
	},
	setOpenBlogEditor(state, data) {
		state.openBlogEditor = data;
	},
	setOpenBlogEditorAddNodeForm(state, data) {
		state.openBlogEditorAddNodeForm = data;
	},
	setCreateComment(state, data) {
		state.createComment = data;
	},
	setMediaList(state, data) {
		console.log("==== setMediaList ==== before", state.mediaList);
		state.mediaList = data;
	},
	setAddNodeFormOpen(state, data) {
		state.addNodeFormOpen = data;
	},
	setEditSingleComment(state, data) {
		state.editComment = data;
	},
	setReplyComment(state, data) {
		state.replyComment = data;
	},
	setTaggedUser(state, data) {
		state.taggedUser = data;
	},
	setKeywordPlanner(state, data) {
		console.log("setKeywordPlanner starting", data);
		if (data.prop === "tableData") {
			let tableData = [];
			tableData = data.val.map((row) => {
				let keyword = row.text;
				let reach = row.keywordIdeaMetrics.avgMonthlySearches;
				let competition = row.keywordIdeaMetrics.competition;
				let highCPC = row.keywordIdeaMetrics.highTopOfPageBidMicros;
				let lowCPC = row.keywordIdeaMetrics.lowTopOfPageBidMicros;
				return [keyword, reach, competition, highCPC, lowCPC];
			});
			state.keywordPlanner[data.prop] = tableData;
		} else {
			state.keywordPlanner[data.prop] = data.val;
		}
		console.log("setKeywordPlanner finished");
	},
	setKeywordData(state, data) {
		state.currentNode.fieldGroups.keyword.fields.keyword.value = data.title;
		state.currentNode.fieldGroups.keyword.fields.reach.value = data.reach;
	},
	removeSocialMediaItem(state, data) {
		state.social.post.media = state.social.post.media.filter(
			(image) => image !== data
		);
	},
	resetSocialPost(state, data) {
		const socialOBJ = new Social();
		state.social.post = socialOBJ.post;
		state.social.post.clientSlug = data.slug;
		state.social.post.nodeID = data.nodeID;
		state.social.post.createdByUsername = data.username;
	},
	setSocial(state, data) {
		if (data.prop) {
			if (data.prop.includes("/")) {
				let words = data.prop.split("/");
				if (words.length === 2) {
					state.social[words[0]][words[1]] = data.val;
				}
				if (words.length === 3) {
					state.social[words[0]][words[1]][words[2]] = data.val;
				}
				if (words.length === 4) {
					state.social[words[0]][words[1]][words[2]][words[3]] = data.val;
				}
			} else {
				state.social[data.prop] = data.val;
			}
		}
	},
	setScheduledBlogPost(state, data) {
		state.scheduledBlogPosts[data.prop] = data.val;
	},
	setCurrentNodeBlogContent(state, data) {
		state.currentNodeBlogContent[data.prop] = data.val;
	},
	setBlogModalOpen(state, data) {
		state.blogModalOpen = data;
	},
	setBlogTitle(state, data) {
		state.currentNodeBlogContent.title = data;
	},
	setListView(state, data) {
		if (data === true || data === false) {
			state.listView = data;

			// Get the current URL
			let url = new URL(window.location.href);

			// Get the search parameters from the URL
			let searchParams = url.searchParams;

			// Set the new parameter
			searchParams.set("list", data);

			// Update the URL with the new parameter
			window.history.pushState({}, "", url.toString());
		}
	},
	setLockContentTree(state, data) {
		state.lockContentTree = data;
		console.log("setLockContentTree:", state.lockContentTree);
	},
	setActiveNode(state, data) {
		state.activeNode = data;
		console.log("active node:", state.activeNode);
	},
	setChangedNode(state, data) {
		state.changedNode = data;
	},
	setSaveContentTree(state, data) {
		state.saveContentTree = data;
	},
	setChangeMovementOfNode(state, data) {
		//1. Find the node that has been changed.

		let liveNode = state.clientContentTree.nodes.find(
			(node) => node.id === data.id
		);
		const contentStrategyNode = state.contentStrategy.nodes.find(
			(node) => node.id === data.id
		);
		const foundNodeInRealCategory = liveNode ? true : false;
		let isImportedNode = false;

		console.log("Live Node: ", liveNode);
		console.log("Content Strategy Node: ", contentStrategyNode);
		console.log("Data ID: ", data.id);

		//normal node
		//imported node in client tree
		//undefined -> means its imported node

		if (contentStrategyNode === (false || undefined)) {
			if (liveNode && !("isImported" in liveNode)) {
				//If its a real node
				console.log("is normal node");
			} else if (liveNode && "isImported" in liveNode) {
				//if its imported node inside real category
				isImportedNode = true;
				console.log("is imported node inside real category");
			} else {
				//if its imported node
				console.log("is imported node");
				state.importedContentTree.nodes.forEach((platform) => {
					const foundNode = platform.nodesData.nodes.find(
						(node) => node.id === data.id
					);
					foundNode ? (liveNode = foundNode) : "";
				});
				isImportedNode = true;
			}
		} else {
			liveNode = contentStrategyNode;
			isImportedNode = true;
			//if its imported node
			console.log("is imported node");
			state.importedContentTree.nodes.forEach((platform) => {
				const foundNode = platform.nodesData.nodes.find(
					(node) => node.id === data.id
				);
				foundNode ? (liveNode = foundNode) : "";
			});
		}

		//2. Remove node from old parent children
		console.log("liveNode", liveNode);

		if (
			state.clientContentTree.nodes.find((node) => node.id === liveNode.parent)
		) {
			console.log("old parent is inside a real category");
			let oldParentObj = state.clientContentTree.nodes.find(
				(node) => node.id === liveNode.parent
			);
			oldParentObj.childIds = oldParentObj.childIds.filter(
				(nodeID) => nodeID !== data.id
			);
			//2a. remove the node from the childCount;
			if (Number(oldParentObj.childCount) > 0) {
				oldParentObj.childCount = Number(oldParentObj.childCount) - 1;
			}
		}

		//3. add node to new parent children
		let newParentObj;
		let newParentIsImported = false;

		console.log("parent id:", data.parentID);

		if (
			state.clientContentTree.nodes.find((node) => node.id === data.parentID)
		) {
			newParentObj = state.clientContentTree.nodes.find(
				(node) => node.id === data.parentID
			);
		} else {
			state.importedContentTree.nodes.forEach((obj) => {
				const categoryIsParent =
					obj.nodesData.category.id === data.parentID
						? obj.nodesData.category
						: false;
				if (categoryIsParent) {
					newParentObj = categoryIsParent;
				} else {
					const foundNode = obj.nodesData.nodes.find(
						(node) => node.id === data.parentID
					);
					if (foundNode) {
						newParentObj = foundNode;
					}
				}
			});
			newParentIsImported = true;
		}

		if (newParentObj && typeof newParentObj === "object") {
			if ("childIds" in newParentObj) {
				newParentObj.childIds.push(data.id);
			}
			//4a. add the node to the childCount
			if ("childCount" in newParentObj) {
				newParentObj.childCount = Number(newParentObj.childCount) + 1;
			}

			//4b. update the level of the moved node
			liveNode.level = Number(newParentObj.level) + 1;
			//5. change parent prop to new parent
			liveNode.parent = data.parentID;
		}

		//6. Inside Links array, change all the sources to the new parent id.
		let liveLink;

		console.log("step-inbwtween:", [
			isImportedNode,
			newParentIsImported,
			foundNodeInRealCategory,
		]);

		if (
			isImportedNode === true &&
			newParentIsImported === false &&
			foundNodeInRealCategory === false
		) {
			//If user is dragging imported node over to a real category
			console.log("user is dragging imported node over to a real category");
			state.clientContentTree.links.push({
				id: "imported",
				source: data.parentID,
				target: data.id,
			});
		} else if (
			isImportedNode === true &&
			newParentIsImported === true &&
			foundNodeInRealCategory === true
		) {
			//if user is dragging imported node back over to imported category list.
			//Remove it from the content tree list.
			console.log(
				"user is dragging imported node back over to imported category list 1"
			);
			state.clientContentTree.links = state.clientContentTree.links.filter(
				(link) => link.target !== data.id
			);
		} else {
			//if user is just moving a real node to a real category
			console.log("user is just moving a real node to a real category");
			liveLink = state.clientContentTree.links.find(
				(link) => link.target === data.id
			);
			liveLink.source = data.parentID;
		}

		//DELETE THE OLD NODE LOCATION
		if (
			isImportedNode === true &&
			newParentIsImported === false &&
			foundNodeInRealCategory === false
		) {
			//If user is dragging imported node over to a real category
			//6. delete node from the importedContentTree.
			console.log("delete node from the importedContentTree.");
			state.importedContentTree.nodes.forEach((platform) => {
				const nodes = platform.nodesData.nodes.filter(
					(node) => node.id !== data.id
				);
				platform.nodesData.nodes = nodes;
			});
		} else if (
			isImportedNode === true &&
			newParentIsImported === true &&
			foundNodeInRealCategory === true
		) {
			//if user is dragging imported node back over to imported category list.
			console.log(
				"user is dragging imported node back over to imported category list 2"
			);
			state.clientContentTree.nodes = state.clientContentTree.nodes.filter(
				(node) => node.id !== data.id
			);
		} else {
			//if user is just moving a real node to a real category
			//6.a Remove this node from state.clientContentTree.nodes as we have the nodeCopy.
			console.log(
				"Remove this node from state.clientContentTree.nodes as we have the nodeCopy"
			);
			state.clientContentTree.nodes = state.clientContentTree.nodes.filter(
				(node) => node.id !== data.id
			);
		}

		//6. Move node object to new location.
		let nodeCopy = JSON.parse(JSON.stringify(liveNode));

		if (
			isImportedNode === true &&
			newParentIsImported === true &&
			foundNodeInRealCategory === true
		) {
			console.log(
				"user is dragging imported node back over to imported category list 3"
			);
			console.log("3-start:", state.importedContentTree.nodes);
			//6.b In state.clientContentTree.nodes, locate the space inbetween data.siblingData.prevSiblingID || data.siblingData.nextSiblingID if set and add nodeCopy inbetween then.

			if (data.siblingBeforeID) {
				state.importedContentTree.nodes.forEach((obj) => {
					const nodes = obj.nodesData.nodes;
					let prevSiblingIndex = nodes.findIndex(
						(node) => node.id === data.siblingBeforeID
					);
					nodes.splice(prevSiblingIndex + 1, 0, nodeCopy);
				});
			} else if (data.siblingNextID) {
				state.importedContentTree.nodes.forEach((obj) => {
					const nodes = obj.nodesData.nodes;
					let nextSiblingIndex = nodes.findIndex(
						(node) => node.id === data.siblingNextID
					);
					nodes.splice(nextSiblingIndex, 0, nodeCopy);
				});
			} else {
				state.importedContentTree.nodes.forEach((obj) => {
					if (obj.category.id === nodeCopy.parent) {
						const nodes = obj.nodesData.nodes;
						nodes.push(nodeCopy);
					}
				});
			}
			console.log("3-end:", state.importedContentTree.nodes);
		} else {
			//6.b In state.clientContentTree.nodes, locate the space inbetween data.siblingData.prevSiblingID || data.siblingData.nextSiblingID if set and add nodeCopy inbetween then.
			if (data.siblingBeforeID) {
				let prevSiblingIndex = state.clientContentTree.nodes.findIndex(
					(node) => node.id === data.siblingBeforeID
				);
				state.clientContentTree.nodes.splice(prevSiblingIndex + 1, 0, nodeCopy);
			} else if (data.siblingNextID) {
				let nextSiblingIndex = state.clientContentTree.nodes.findIndex(
					(node) => node.id === data.siblingNextID
				);
				state.clientContentTree.nodes.splice(nextSiblingIndex, 0, nodeCopy);
			} else {
				state.clientContentTree.nodes.push(nodeCopy);
			}
		}
	},
	setUpdateIndexesOfNodes(state, arr) {
		console.log("setUpdateIndexesOfNodes starting");
		state.clientContentTree.nodes.forEach((node) => {
			const response = arr.filter((nodeb) => nodeb.id === node.id);
			if (response.length > 0) {
				const passedNodeObject = response[0];
				node.name = passedNodeObject.name;
			}
		});
	},
	setComponents(state, data) {
		if (data.prop) {
			if (data.prop.includes("/")) {
				let words = data.prop.split("/");
				if (words.length === 2) {
					state.components[words[0]][words[1]] = data.val;
				}
				if (words.length === 3) {
					state.components[words[0]][words[1]][words[2]] = data.val;
				}
				if (words.length === 4) {
					state.components[words[0]][words[1]][words[2]][words[3]] = data.val;
				}
			} else {
				state.components[data.prop] = data.val;
			}
		}
	},
	graphDataUpdate(state, data) {
		if (data.type === "paid") {
			state.components.paidSocial.HeaderGraphBlock.paidChartOptions;
		} else {
			state.components.paidSocial.HeaderGraphBlock.organicChartOptions;
		}
	},
	setWaitingForPostToPublish(state, data) {
		state.waitingForPostToPublish = data;
	},
	setLoopThatChecksIfPostPublishedRunning(state, data) {
		state.loopThatChecksIfPostPublishedRunning = data;
	},
	setIsCreatingNewNode(state, data) {
		state.isCreatingNewNode = data;
	},
	setIsCreatingNewNodeToAddToDB: (state, data) => {
		state.isCreatingNewNodeToAddToDB = data;
	},
	setZoomToFitContentTree: (state) => {
		state.zoomToFitContentTree = !state.zoomToFitContentTree;
	},
	setNoDragContentListItems: (state) => {
		const links = state.clientContentTree.links.filter(
			(link) => link.sibling === true
		);
		let arr = [];
		let set = new Set();

		for (let link of links) {
			if (!set.has(link.source)) {
				set.add(link.source);
				arr.push(link.source);
			}
			if (!set.has(link.target)) {
				set.add(link.target);
				arr.push(link.target);
			}
		}
		arr.push(state.clientContentTree.rootNodeId);
		state.contentTreeSiblingIds = arr;
	},
	clearBlogData: (state) => {
		state.scheduledBlogPosts = new ScheduledBlogPosts();
		state.currentNodeBlogContent = new NodeBlogContent();
	},
	setNodeWasOrIsPosting: (state, data) => {
		state.nodeWasOrIsPosting = data;
	},
	setClientFacebookData: (state, data) => {
		state.clientData.facebookData[data.metric] = data.data;
	},
	setAIPopUpFields: (state, data) => {
		if (data.prop) {
			if (data.prop.includes("/")) {
				let words = data.prop.split("/");
				if (words.length === 2) {
					state.AIPopUpFields[words[0]][words[1]] = data.val;
				}
				if (words.length === 3) {
					state.AIPopUpFields[words[0]][words[1]][words[2]] = data.val;
				}
				if (words.length === 4) {
					state.AIPopUpFields[words[0]][words[1]][words[2]][words[3]] =
						data.val;
				}
			} else {
				state.AIPopUpFields[data.prop] = data.val;
			}
		}
	},
	setRemoveRowLoader: (state, data) => {
		state.loaders.removeRowLoader = data;
	},
	setLoaders: (state, data) => {
		state.loaders[data.prop] = data.val;
	},
	setCloseNodeOverview: (state) => {
		state.closeNodeOverview = !state.closeNodeOverview;
	},
	setAuthUsername: (state, data) => {
		state.authUsername = data;
	},
	setUserIsRequiredToReloadPage: (state, data) => {
		state.userIsRequiredToReloadPage = data;
	},
	setOldNodeId: (state, data) => {
		state.oldNodeId = data;
	},
	setZoomingAnimationOnTreeActive: (state, data) => {
		state.zoomingAnimationOnTreeActive = data;
	},
	updateRoute(state, payload) {
		state.route = { ...state.route, ...payload };
	},
	setGoogleAccessToken(state, data) {
		state.googleAccessToken = data;
	},
	setLockListCategories(state, data) {
		state.lockListCategories = data;
	},
	setImportModalOpen(state, data) {
		state.importModalOpen = data;
	},
	setImportedContentTree(state, data) {
		state.importedContentTree = data;
	},
	setACFPickerData(state, data) {
		state.acfPickerData = data;
	},
	setContentTreeWasResized(state, data) {
		state.contentTreeWasResized = data;
	},
	setReportProblem(state, data) {
		state.reportProblem[data.prop] = data.val;
	},
	setTaggedUsers(state, data) {
		state.taggedUsers[data.prop] = data.val;
	},
	setMutliSelectNodeCategories(state, data) {
		state.mutliSelectNodeCategories[data.prop] = data.val;
	},
	resetReportProblem(state) {
		state.reportProblem = new reportProblem();
	},
	setBlogContentIsInDB(state, data) {
		state.blogContentIsInDB = data;
	},
	setNodeField(state, payLoad) {
		state.currentNode.fieldGroups[payLoad.fieldGroup].fields[
			payLoad.field
		].value = payLoad.value;
		const node = state.clientContentTree.nodes.find(
			(node) => node.id === state.currentNode.id
		);
		node.status = payLoad.value;
		state.hasCurrentNodeBeenEdited = true;
	},
	setReloadContentTree(state, data) {
		state.reloadContentTree = data;
	},
	setUserOpenedBlogOrSocialEditor(state, data) {
		state.userOpenedBlogOrSocialEditor = data;
	},
	setShowEditor(state, data) {
		state.showEditor = data;
	},
	setOpenaddNodeClickTitle(state, data) {
		console.log("setOpenaddNodeClickTitle", data);
		state.openaddNodeClickTitle = data;
	},
	setContentStrategy: (state, data) => {
		console.log("setContentStrategy", data);
		if (data.prop) {
			if (data.prop.includes("/")) {
				let words = data.prop.split("/");
				if (words.length === 2) {
					state.contentStrategy[words[0]][words[1]] = data.val;
				}
				if (words.length === 3) {
					state.contentStrategy[words[0]][words[1]][words[2]] = data.val;
				}
				if (words.length === 4) {
					state.contentStrategy[words[0]][words[1]][words[2]][words[3]] =
						data.val;
				}
			} else {
				state.contentStrategy[data.prop] = data.val;
			}
		}
	},
};
